<template>
  <div class="main-wrapper">
    <div class="d-md-none" id="hamburger-wrapper">
      <Slide id="hamburger-menu" right>
        <div class="list-group list-group-flush">
          <router-link v-bind:to="'/'" class="list-group-item list-group-item-action">Home</router-link>
          <router-link v-bind:to="'/river_deltas'" class="list-group-item list-group-item-action">River Deltas</router-link>
          <router-link v-bind:to="'/consistent_histories'" class="list-group-item list-group-item-action">Consistent Histories</router-link>
					<router-link v-bind:to="'/spins'" class="list-group-item list-group-item-action">Spins</router-link>
          <router-link v-bind:to="'/graphs'" class="list-group-item list-group-item-action">Graphs</router-link>

        </div>
      </Slide>
    </div>
    <div class="d-flex" id="wrapper">
      <!-- Sidebar -->
      <div class="border-right" id="sidebar-wrapper">
        <div class="sidebar-heading">
          <img :src="require(`@/assets/images${this.$router.history.current.path}_nav.png`)" style="max-width:100%"/>
        </div>
        <div class="list-group list-group-flush">
          <router-link v-bind:to="'/'" class="list-group-item list-group-item-action">Home</router-link>
          <router-link v-bind:to="'/river_deltas'" class="list-group-item list-group-item-action">River Deltas</router-link>
          <router-link v-bind:to="'/consistent_histories'" class="list-group-item list-group-item-action">Consistent Histories</router-link>
          <router-link v-bind:to="'/spins'" class="list-group-item list-group-item-action">Spins</router-link>
					<router-link v-bind:to="'/graphs'" class="list-group-item list-group-item-action">Graphs</router-link>

        </div>
      </div>
      <!-- /#sidebar-wrapper -->

      <!-- Page Content -->
      <div id="page-content-wrapper">

        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
      <!-- /#page-content-wrapper -->

    </div>
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
  components: {
    Slide
  },
  data () {
    return {
      title: 'Ian Wilson',
    }
  }
}
</script>

<style>
</style>
