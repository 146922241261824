<template>
  <div>
    <div class='markdown-body' v-html="content"></div>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css'
import '@/assets/atom-one-light.css'
import 'github-markdown-css/github-markdown.css'

var md = require('markdown-it')({html: true}),
    mk = require('markdown-it-katex'),
    mh = require('markdown-it-highlightjs'),
		markdown_it_headings = require('markdown-it-github-headings'),
		markdown_it_collapsible = require("markdown-it-collapsible");

md.use(mk)
md.use(mh)
md.use(markdown_it_collapsible)
md.use(markdown_it_headings, {prefixHeadingIds: false})
const content = md.render(`

# River Deltas
- [GitHub: Measuring chaos](https://github.com/wilsonia/delta-chaos)
- [GitHub: Avulsion prediction](https://github.com/wilsonia/avulsion-predict)
- [GitHub: Debugged delta model](https://github.com/wilsonia/bifurcation_network)

## Contents
- [Background](#background)
- [Visualizing delta evolution](#visualizing-delta-evolution)
- [Measuring chaos](#measuring-chaos)
- [Identifying avulsions](#identifying-avulsions)
- [Predicting avulsions](#predicting-avulsions)
  - [Ridge classification](#ridge-classification)
  - [Classifier performance](#classifier-performance)
- [Summary of delta model](#summary-of-delta-model)
- [Acknowledgements](#acknowledgements)

## Background

River deltas are important geomorphological features where a river meets a large body of water on flat land. In addition to hosting sensitive estuary ecosystems, deltas are often highly populated by humans due to their agricultural and navigational benefits. Deltas tend to develop a tree structure over time and space as sediment deposition leads to the creation (and reoccupation of) new channels. This process may threaten human infrastructure due to how rapidly channel migration may occur. Such an event is called an *avulsion*, and the prediction of their occurrence is an active area of research.

To explore the predictability of river deltas, we attempt to foresee avulsions within data generated by a 1D river delta model. While this model is predictive in nature, it is physically grounded and can be fit to a wide range of natural deltas. We pretend as if its output is historical and physically measured with the hope that our methods will apply to field cases with minimal adaptation.

As a proof of concept, we develop a classifier indicating whether an avulsion will occur in the next 23 days given 9 days of historical water flow and sediment deposition data. Such an early warning system could give communities at risk of destruction time to evacuate. Given that chaotic behavior occurs with this model, precise predictions are difficult to assert due to sensitive dependence on initial conditions. We experiment with asking a broad question instead.


## Visualizing delta evolution

|<iframe src="avulsion.html"  onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px'; this.style.width=(this.contentWindow.document.body.scrollWidth+20)+'px';"></iframe>|
|:--:|
| A delta evolution in which avulsions are recognized by the sudden shifts in height between pairs of branches.|

To visualize the evolution of deltas, the change in bed elevations at nodes over time is animated. Since the transverse (across-river) separation between nodes is not accounted for in the model, they are spaced according to a Cantor set in this dimension (labeled $y$) to emphasize the symmetric geometry of our delta. Longitudinal (down-river) distance between nodes varies significantly with tree depth for many interesting deltas. This produces a challenging visualization, so instead a constant separation between nodes in this dimension (labeled $x$) is used.

So far, node positioning has been chosen for visual clarity and has no relation to the specifics of our delta. Now, the $z(t)$ coordinate of each node is set equal to that node's bed elevation $\\eta$ for a given point in time. Plotting these nodes (and the connecting branches between them) produces a visual representation of the river bed. This method is demonstrated in [this jupyter notebook](https://github.com/wilsonia/delta-chaos/blob/main/visualization/avulsion/Avulsion.ipynb).

## Measuring chaos
An important feature of a chaotic dynamical system is sensitive dependence on initial conditions. Specifically, trajectories with initial conditions in a small neighborhood around some base case diverge
exponentially in phase space, meaning
$$ |d(t)| = d_0 e^{\\lambda t} $$
where $\\lambda$ is called the *Lyapunov exponent*. This exponent characterizes how quickly trajectories diverge. A convenient measure of the time scale for which behavior is reasonably "predictable" is given by its inverse, $\\frac{1}{\\lambda}$. Measuring this value for a given chaotic delta provides an upper limit of the time period for which models can aspire to predict.

Distance between a base trajectory and a perturbed trajectory is measured by summing the absolute values of bed elevation differences at each node.
$$    |d(t)|= \\sum_{j=1}^7  |\\eta^{perturbed}_j - \\eta^{base}_j |  $$

|<iframe src="single_lyap.html"  onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px'; this.style.width=(this.contentWindow.document.body.scrollWidth+20)+'px';"></iframe>|
|:--:|
| Fitting a Lyapunov exponent for a single model run.|


30 perturbed trajectories are computed, and divergence $|d(t)|$ is averaged amongst them.

|![](average_run.png)|
|:--:|
| Fitting a Lyapunov exponent for the averaged runs.|

Notably, when measuring the Lyapunov exponent for the delta defined in column 1 of Table S1 in [Salter et al’s](https://www.pnas.org/doi/full/10.1073/pnas.2010416117) appendix, the value for the averaged runs is 1.42, which is comparable to their reported maximal Lyapunov exponent of 1.53.

## Identifying avulsions
In order to train a classifier for avulsion prediction, all avulsions in generated training datasets need to be identified. We develop a simple algorithm for doing so.

An avulsion is typically characterized as a large and rapid re-occupation of a relatively unused branch. If one node avulses, the sole sibling node must respond in a roughly equal and opposite way. The following figure shows that during an avulsion, the rate of change of bed elevations differ in sign between sibling nodes, yet both experience a similar absolute change.

![](avulsion.png)

To measure this, we propose an avulsion signal is defined as
$$  \\text{avulsion signal} = \\left|\\frac{d \\eta_1}{dt} - \\frac{d \\eta_2}{dt}\\right| - \\left|\\frac{d \\eta_1}{dt} + \\frac{d \\eta_2}{dt}\\right| $$

The first term rewards the signal when bed elevations move in opposite directions, while the second term penalizes the signal when they move in the same direction. The following figure confirms that peaks on this signal coincide with avulsions.

![](avulsions.png)

SciPy's peak detection algorithm is used to collect these points. The width and height of these peaks are adjustable depending on the desired definition of an avulsion. These peaks are used to generate classification labels. Specifically, 9 day histories are labeled as preceding an avulsion (or not) in the next 23 days.

## Predicting avulsions
### Ridge classification
A linear regression classifier determines a function of best fit by minimizing error, solving the least squares problem

$$ \\beta_{MLE} = \\text{argmin}\\left(||A\\beta-y||^2 + \\frac{\\lambda}{2}||\\beta||^p_p\\right) $$

where $\\lambda$ is a hyper-parameter scaling the penalization, and $p$ is the degree of regularization, with $p=2$ being the most common choice.

This equation can be solved by differentiating the argument with respect to $\beta$ and equating to zero to find the minimum, resulting in

$$ \\beta_{MLE} = (A^TA + \\lambda I)^{-1} A^Ty  $$

Without regularization, overfitting can occur when $\\beta$ parameters become too large, allowing for excessive parameter variance. Furthermore, $A^TA$ can be non-invertible, meaning that $\\beta_{MLE}$ cannot be computed, however $A^TA + \\lambda I$ can always be inverted for some choice of $\\lambda$. Including the penalty ensures that a solution may be computed, and that the smallest possible $\\beta$ are found.





### Classifier performance

A separate set of model runs are generated for testing purposes. We evaluate performance by calculating "early alerts validity" and "avulsions predicted" ratios. The early alerts validity gives the percentage of early alerts that are actually followed by an avulsion event within the defined avulsion period. A low early alert validity would correspond to frequent false alarms, diminishing the model's usefulness. The avulsions predicted ratio gives the percentage of avulsions that were preceded by an early alert. A low avulsions predicted ratio would correspond to avulsion events frequently occurring without warning from the model.

Dimensionality is reduced using principal component analysis and linear discriminant analysis in conjunction, after which a ridge classifier is fit. We achieved the following results for the delta of interest:


| **Performance Measure**                       | **Score** |
|------------------------------------------|------------------------------------|
|  Early alert validity                    |               78.7%                  |
|  Avulsions predicted                     |               91.6%                  |
|               Training accuracy          |               85.7%                  |
|               Testing accuracy           |               85.6%                  |

The low training-to-testing accuracy drop implies negligible overfitting. Avulsion detection performs remarkably well for a linear classifier, while early alert validity leaves room for improvement. While the model can be tuned for increased alert validity, it comes at the expense of avulsion detection.


## Summary of delta model

The river delta model used to generate data for this work was developed by [Salter, et al. in 2020](https://www.pnas.org/doi/abs/10.1073/pnas.2010416117?doi=10.1073%2Fpnas.2010416117). The following section briefly summarizes the historical development of this model with the intent of disclosing the model's assumptions and dynamical equations.

With their model, Salter et al. demonstrate several important features of river deltas in the field. Depending on specific conditions, a delta may have
- Fixed point solutions (long-term stability in branch occupation)
- Stable limit cycle solutions (long-term oscillatory branch closure and re-occupation)
- Chaotic solutions (short term erratic and seemingly unpredictable behavior)

Given these feature, we trust that this model accurately represents river deltas in the field.

We begin with the 1D river bifurcation model published by [Wang et al. in 1995](https://www.tandfonline.com/doi/abs/10.1080/00221689509498549).  A river bifurcation refers to a point where an upstream branch splits into two downstream branches. In Wang et al.'s model, the time evolution of water discharge rate $Q_j$ and sediment transport rate $S_j$ in each branch $j$ downstream of a bifurcation is predicted.

|![](wang_bif.png)|
|:--:|
| Sketch of river bifurcation (reprinted from [Wang et. al](https://www.tandfonline.com/doi/abs/10.1080/00221689509498549)) |

This model is one dimensional, meaning that as longitudinal (downriver) distance increases, the flow and sediment in relevant branches change; in this way $Q$ and $S$ vary with distance. They proposed the following set of physical conditions, which are still in use in the river delta model.

#### Mass-balance for water
$$ Q_1 + Q_2 = Q_0 $$

#### Mass-balance for sediment
$$ S_1 + S_2 = S_0 $$

#### Water level constant amongst branches
$$   H_0 = H_1 = H_2 $$

Other physical conditions and dynamics were updated by [Bolla Pittaluga et al. in 2003](https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2001WR001112) to incorporate more physics and to eliminate empirical parameters. They also incorporated transverse degree of freedom in water and sediment flow in the region just before a bifurcation, creating a "quasi-2D" model.

|![](quasi_2d.png)|
|:--:|
| Quasi-2D nodal discretization (reprinted from [Bolla Pittaluga et al.](https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2001WR001112)). Note that branches are now labeled with letters rather than numbers, $Q$ represents water flow rate, $Q_s$ represents sediment transport rate, $b$ represents channel width, and $D$ represents channel depth. |

The dynamics for water at all points in the model are given by the following equations:
#### Water flow dynamics equation 1
 $$     \\frac{\\partial D}{\\partial t} = -\\frac{1}{b} \\frac{\\partial Q}{\\partial x} $$

#### Water flow dynamics equation 2
$$      \\frac{\\partial Q}{\\partial t}  = \\frac{Q^2}{bD^2}\\left(b\\frac{\\partial D}{\\partial x} + D\\frac{\\partial b}{\\partial x} \\right) - \\frac{2Q}{bD}\\frac{\\partial Q}{\\partial x} - gbD\\left(\\frac{\\partial H}{\\partial x} + j \\right)  $$
where $C$ is the Chezy coefficient, $g$ represents gravitational acceleration, and
$$ j = \\frac{Q^2}{gC^2b^2D^3} $$

Sediment transport is modeled by the Exner continuity equation
#### Sediment continuity equation
$$ (1-p)b\\frac{\\partial \\eta} {\\partial t} + \\frac{\\partial bq}{\\partial x} = 0 $$

where $p$ represents water density, $\\eta = H-D$ represents bed elevation, and $q(Q,D)$ gives the sediment discharge per unit width. This $q$ function utilizes the Shields parameter, which is dimensionless and represents shear stress for sediment.

In [2017, Salter et al.](https://agupubs.onlinelibrary.wiley.com/doi/full/10.1002/2017JF004350)  further extended the bifrucation model by discretizing downstream branches, allowing variation in water and sediment flux (and consequently bed elevation) more finely within the branches.

|![](salter_bif.png)|
|:--:|
| Quasi-2D node with downstream branches discretization (reprinted from [Salter et al.](https://agupubs.onlinelibrary.wiley.com/doi/full/10.1002/2017JF004350)) |

It allows each nodal point cell's bed elevation to evolve independent of its downstream branch elevation; in Bolla Pittaluga et al., these are assumed to be equal. The finer downstream modeling allows sediment deposition in the branches, affecting conditions upstream; in this way Salter et al.'s model captures upstream information flow.

In 2020, Salter et al. constructed a river delta model by connecting downstream branches to further river bifurcations, resulting in a tree of coupled bifurcations.

|![](salter_tree.png)|
|:--:|
| (A network of coupled river bifurcations (reprinted from [Salter et al.](https://www.pnas.org/doi/abs/10.1073/pnas.2010416117?doi=10.1073%2Fpnas.2010416117)) |

Phase space is spanned by a tree of flow $Q(x)$, sediment $Q_s(x)$, and depth $D(x)$. In place of depth, it is often more convenient to work with bed elevation $\\eta(x) = H(x) - D(x)$ where $H(x)$ is the water surface elevation; $\\eta(x)$ measures the elevation of the river bed without regard to the depth of water above.

#### Summary
Branches connecting bifurcations are discretized in one dimension except for a short two dimensional stretch upstream of the bifurcation. Water flow equations [1](#water-flow-dynamics-equation-1) and [2](#water-flow-dynamics-equation-2) and the [sediment continuity equation](#sediment-continuity-equation) are imposed at all cell boundaries, and the delta's evolution is computed numerically given a set of initial conditions.

## Acknowledgements
The work presented here was completed for projects in the AMATH 575 (Non-linear Dynamics) and AMATH 582 (Computational Methods for Data Analysis) courses at University of Washington.

`)
export default {
  data() {
    return {
      content
    }
  }
}
</script>
