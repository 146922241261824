<template>
  <div>
    <div class='markdown-body' v-html="content"></div>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css'
import '@/assets/atom-one-light.css'
import 'github-markdown-css/github-markdown.css'

var md = require('markdown-it')(),
    mk = require('markdown-it-katex'),
    mh = require('markdown-it-highlightjs')

md.use(mk)
md.use(mh)
const content = md.render(`
# Representing Degree Requirements with Directed Acyclic Graphs
This collaborative project started as an idea for OSU's Hackathon, and has been sporadically developed since.

The idea is to visualize the sequence of courses taken to obtain a degree as a force directed network. Degree networks could be compared amongst majors/colleges, and measures could be defined for how "pigeonholing" a program is. This could help students plan schedules or choose a major/college.

Because some degree requirements are satisfied by many different courses, we need to account for uncertainty in these networks. We introduce a special type of node that represents a decision, which allows us to include all possible course options on the graph.

For more information, view the project's [summary page](https://course-network.github.io/degree-requirement-dag/) or [repository](https://github.com/course-network/degree-requirement-dag).
`)

export default {
  data() {
    return {
      content
    }
  }
}
</script>
