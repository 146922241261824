import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/style.css'
import Index from './components/Index'
import Graphs from './components/Graphs'
import Spins from './components/Spins'
import Consistent_Histories from './components/Consistent_Histories'
import River_Deltas from './components/River_Deltas'

Vue.config.productionTip = false

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Index },
  { path: '/graphs/', component: Graphs },
	{ path: '/spins/', component: Spins },
	{ path: '/river_deltas/', component: River_Deltas },
  { path: '/consistent_histories/', component: Consistent_Histories },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };  // Go to the top of the page if no hash
  },
})

new Vue({
  template: '<App/>',
  components: { App },
  router,
  render: h => h(App)
}).$mount('#app')//mount the router on the app
