<template>
  <div>
    <div class='markdown-body' v-html="content"></div>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css'
import '@/assets/atom-one-light.css'
import 'github-markdown-css/github-markdown.css'

var md = require('markdown-it')({html: true}),
    mk = require('markdown-it-katex'),
    mh = require('markdown-it-highlightjs'),
		markdown_it_headings = require('markdown-it-github-headings'),
		markdown_it_collapsible = require("markdown-it-collapsible");

md.use(mk)
md.use(mh)
md.use(markdown_it_collapsible)
md.use(markdown_it_headings, {prefixHeadingIds: false})
const content = md.render(`

# Consistent Histories

## Contents
- [Background](#background)
- [Quantum dynamics](#quantum-dynamics)
	- [Unitary evolution postulate](#unitary-evolution-postulate)
	- [Projection postulate](#projection-postulate)
	- [Probability postulate](#probability-postulate)
- [Issues with measurement](#issues-with-measurement)
- [Unitary measurement](#unitary-measurement)
  - [Spin system](#spin-system)
  - [Pointer system](#pointer-system)
  - [von Neumann measurement scheme](#von-neumann-measurement-scheme)
- [Extending the Born Rule](#extending-the-born-rule)
- [Consistency conditions](#consistency-conditions)
- [Mixed states](#mixed-states)
  - [Density operator Born Rule](#density-operator-born-rule)

## Background

This page exists to provide a brief summary of my [my Bachelor's thesis](https://ir.library.oregonstate.edu/concern/undergraduate_thesis_or_projects/sn00b494x) research. It goes into more detail than the abstract, expands on ideas that were not fully developed at the time of writing, and makes a few corrections. It assumes an undergraduate level understanding of quantum mechanics, specifically the content covered in [Oregon State's quantum fundamentals course](https://physics.oregonstate.edu/syllabus/ph425).

The idea of my thesis was to describe the Stern-Gerlach experiment using the consistent histories approach to quantum mechanics. This serves multiple purposes:

- For a proposed approach to quantum mechanics to be viable, it must (at minimum) reproduce the predictions of the standard approach that agree with experiment. Therefore, it is worthwhile demonstrating the compatibility of the *standard* and *consistent histories* approaches.
- By contrasting the descriptions of a keystone experiment in these approaches, the merits of consistent histories can be detailed accessibly.
- For those with a spins-first education in quantum mechanics, the thesis serves as a supplement for continued learning in quantum foundations research.


## Quantum dynamics

The standard approach postulates two different types of time evolution:
1. *Unitary* evolution, which applies when the system is not being observed by a "classical observer". This evolution is reversible.
	#### Unitary evolution postulate
  	> The time evolution of a quantum system $\\ket{\\psi}$ is determined by the Hamiltonian operator $H(t)$ through the Schrödinger equation
    > $$i\\hbar \\frac{d}{dt} \\ket{\\psi(t)} = H(t)\\ket{\\psi(t)}$$

2. *State collapse*, which applies when the system is being observed by a "classical observer". This evolution is not reversible.
	#### Projection postulate
	> If the measurement of the physical variable $\\mathcal{A}$ on the system in the state $\\ket{\\psi}$ gives the result $a_n$, the state of the system immediately after the measurement is the normalized projection of $\\ket{\\psi}$ onto $\\ket{a_n}$:
	>$$\\ket{\\psi^\\prime} =  \\frac{P^\\mathcal{A}_n\\ket{\\psi}}{\\sqrt{\\bra{\\psi}P^\\mathcal{A}_n\\ket{\\psi}}}$$
	>where $P^\\mathcal{A}_n = \\ket{a_n}\\bra{a_n}$ is the projection operator for the $\\ket{a_n}$ state. The superscript $\\mathcal{A}$ labels the observable, while the subscript $n$ labels a particular eigenvalue of that observable.

Notice that by the state collapse mechanism, $\\ket{\\psi}$ instantaneously evolves to $\\ket{a_n}$ upon "interaction with a classical observer".

To exemplify state collapse, consider the below spins experiment.

<iframe src="spins.html?experiment=twoA" width="100%" height=400"></iframe>

Experiment 1. A demonstration of "state preparation", where only electrons measured spin-up for $S_z$ go on to be measured for $S_x$.

The first analyzer prepares the spin state in $\\ket{+_z}$. At the second analyzer, the $S_x$ measurement results in the state collapsing to
$$ \\ket{\\psi^\\prime} = \\frac{\\ket{\\pm_x}\\braket{\\pm_x|+_z}}{\\sqrt{\\braket{+_z|\\pm_x}\\braket{\\pm_x|+_z}}} = \\ket{\\pm_x},$$
with $\\ket{\\pm_x}$ meaning $\\ket{+_x}$ or $\\ket{-_x}$.


The probability of measuring a certain result is postulated by the "Born Rule":

 -
 	#### Probability postulate
  > When measuring physical variable $A$, the probability $\\mathcal{P}(n)$ of obtaining result $a_n$ corresponding to $\\ket{a_n}$  is equal to
  > $$\\mathcal{P}(n) = |\\braket{a_n|\\psi}|^2 $$
	> or equivalently
	> $$\\mathcal{P}(n) = \\bra{\\psi} P^A_n \\ket{\\psi}$$

Exemplifying this, the $S_x$ analyzer in Experiment 1 is equally likely to measure spin-up as spin down:
$$\\mathcal{P}(+_x) = |\\braket{+_x|+_z}|^2 = \\frac{1}{2}$$
$$\\mathcal{P}(-_x) = |\\braket{-_x|+_z}|^2 = \\frac{1}{2}$$

## Issues with measurement
Despite being fundamental to the standard approach, the classical observer is not well defined. Must the classical observer be conscious? If so, how is consciousness defined, and how much is required?

Problematic claims that atomic properties may be understood only through their interactions with human or "man-like" brains are widespread in popular literature,

> "The crucial feature of atomic physics is that the human observer is not only necessary to observe the properties of an object, but is necessary even to define these properties." -- Fritjof Capra, *The Tao of Physics*

yet such anthropocentric reasoning [hardly misrepresents the standard approach](https://arxiv.org/abs/quant-ph/0103043).

In addition to these interpretational issues, we assert that state collapse artificially limits the scope of quantum theory. For example, standard quantum mechanics is incapable of making statements about the [observers themselves](https://www.nature.com/articles/s41467-018-05739-8) or [any system in the early universe](https://arxiv.org/abs/1604.01385).

These considerations motivate the use of an approach to quantum mechanics that does not postulate state collapse.

In the consistent histories approach, only unitary evolution is postulated. In the absence of foundational assumptions about measurement dynamics, measurement must be described by the theory itself.

## Unitary measurement

Measurement can be modeled as a unitary process [by implementing von Neumann's measurement scheme](https://ir.library.oregonstate.edu/concern/undergraduate_thesis_or_projects/sn00b494x). In this scheme, the Stern-Gerlach analyzer is considered to be a quantum system (acting as a ["pointer system"](https://arxiv.org/abs/1508.04101)). Measurement of the electron's spin is characterized as an entanglement between the spin and analyzer systems. This is the essence of [Hugh Everett's relative state formalism](http://www.weylmann.com/relative_state.pdf). To do this, we define our spin and pointer systems.

### Spin system
In the Stern-Gerlach experiment, the measured system is the electron. The physical variable measured is the internal angular momentum (spin) of the electron. So, the state of the electron $\\ket{\\psi_{s}} \\in \\mathcal{H_s}$ is written as a general superposition of spin eigenstates
$$\\ket{\\psi_{s}} = c_+ \\ket{+_z} + c_- \\ket{-_z}$$

We now apply the spin eigenstate projection operators (where $z$ labels the direction of the component of spin measured)
$$P^{S_z}_\\pm = \\ket{\\pm_z}\\bra{\\pm_z}$$
to the spin state $\\ket{\\psi_s}$
$$P^{S_z}_\\pm \\ket{\\psi_s} = c_\\pm \\ket{\\pm_z},$$
and see these projections result in the terms that compose $\\ket{\\psi_s}$. Note that $S_z$ denotes the physical variable for which $\\ket{\\pm_z}$ is an eigenstate.

Now we can rewrite the expansion of the general spin state in terms of projection operators rather than arbitrary constants,
$$\\ket{\\psi_{s}} = P^{S_z}_+ \\ket{\\psi_{s}} + P^{S_z}_- \\ket{\\psi_{s}} = \\sum_n{P^{S_z}_n} \\ket{\\psi_{s}}$$
where $n$ sums over $(+, -)$.

### Pointer system
Now we consider the Stern-Gerlach apparatus itself a quantum system. Naming some states we have intuition with, let $\\ket{+_\\epsilon}$ and $\\ket{-_\\epsilon}$ represent the state of the apparatus having measured spin-up and spin-down, respectively. We also define a ["ready" state $\\ket{\\varnothing_\\epsilon}$ asserting no measurement has been made](https://arxiv.org/abs/quant-ph/0312059).
These are called "pointer" states in analogy to the macroscopic needle of a measuring device. Since these pointer states represent an exhaustive set of mutually exclusive outcomes, we assert orthogonality
$$ \\braket{\\pm_\\epsilon | \\mp_\\epsilon} = 0 $$
$$ \\braket{\\pm_\\epsilon | \\varnothing_\\epsilon} = 0, $$
normality
$$ \\braket{\\pm_\\epsilon | \\pm_\\epsilon } = 1 $$
$$ \\braket{\\varnothing_\\epsilon | \\varnothing_\\epsilon } = 1, $$
and completeness
$$ \\sum_n P^\\epsilon_n = P^\\epsilon_+ +  P^\\epsilon_- +  P^\\epsilon_\\varnothing = I^\\epsilon. $$
In other words, $\\{ \\ket{+_\\epsilon}, \\ket{-_\\epsilon}, \\ket{\\varnothing_\\epsilon} \\}$ is an orthonormal and complete basis. So, we can expand the general apparatus state $\\ket{\\psi_\\epsilon} \\in \\mathcal{H}_\\epsilon$ in this basis
$$ \\ket{\\psi_\\epsilon} = \\sum_n P^\\epsilon_n \\ket{\\psi_\\epsilon} $$
where $n$ sums over $(+, -, \\varnothing)$.

### von Neumann measurement scheme
Now we consider the composite spin-pointer system $\\mathcal{H}_s \\otimes \\mathcal{H}_\\epsilon$. The von Neumann measurement scheme describes measurement as the entanglement of the spin and apparatus systems, written as a linear map
$$\\Big(P^{S_z}_+ \\ket{\\psi_{s}} + P^{S_z}_- \\ket{\\psi_{s}}\\Big) \\otimes \\ket{\\varnothing_\\epsilon} \\longmapsto \\Big(P^{S_z}_+ \\ket{\\psi_{s}} \\otimes \\ket{+_\\epsilon}\\Big) + \\Big(P^{S_z}_- \\ket{\\psi_{s}} \\otimes \\ket{-_\\epsilon}\\Big),$$
which may also be expressed as
$$\\sum_n{P^{S_z}_n} \\ket{\\psi_{s}} \\otimes \\ket{\\varnothing_\\epsilon} \\longmapsto \\sum_n\\Big(P^{S_z}_n \\ket{\\psi_{s}} \\otimes \\ket{n_\\epsilon}\\Big)$$
where $n$ sums over $(+, -)$.

The unitary operator that satisfies this mapping from time $t_0$ to time $t_1$ is
$$U(t_1, t_0) = P^{S_z}_+ \\otimes \\Big( \\ket{+_\\epsilon}\\bra{\\varnothing_\\epsilon} + \\ket{\\varnothing_\\epsilon}\\bra{+_\\epsilon} + P^\\epsilon_- \\Big) + P^{S_z}_- \\otimes \\Big( \\ket{-_\\epsilon}\\bra{\\varnothing_\\epsilon} + \\ket{\\varnothing_\\epsilon}\\bra{-_\\epsilon} + P^\\epsilon_+ \\Big).$$
which may be simplified to
$$U(t_1, t_0) = P^{S_z}_+ \\otimes \\mathcal{S}^\\epsilon_{\\varnothing, +} + P^{S_z}_- \\otimes \\mathcal{S}^\\epsilon_{\\varnothing, -} $$
by defining the "swaperator"
$$\\mathcal{S}^\\epsilon_{\\varnothing, \\pm} = \\ket{\\pm_\\epsilon}\\bra{\\varnothing_\\epsilon} + \\ket{\\varnothing_\\epsilon}\\bra{\\pm_\\epsilon} + P^\\epsilon_\\mp $$

+++ Click to see a more general swaperator
We suggest that the swaperator can be used for  simple unitary measurement of quantum systems with more than two (or even infinite) degrees of freedom. For some physical variable $A$, the swaperator for the $n$ result is
$$\\mathcal{S}^A_{\\varnothing, n} = \\ket{n_A}\\bra{\\varnothing_A} + \\ket{\\varnothing_A}\\bra{n_A} + I^A - P^A_n - P^A_\\varnothing$$
++++

We note that $S$ is self-adjoint
$$ S^\\dagger = S $$
and unitary
$$ S^\\dagger S = I$$

## Extending the Born Rule
Now that measurement is described as a unitary process, how can we make predictions about its outcome? In the standard approach, the Born Rule gives the probability of outcomes of state collapse.

In general, the sample space of a physical variable consists of an exhaustive set of mutually exclusive outcomes, called *events*. The consistent histories approach assigns a mathematical object and physical meaning to events, and makes predictions about these rather than "measurement results".

The sample space for a physical variable is found by decomposing the identity in that variable's eigenbasis. Each term in the decomposition represents an event. For example, the sample space of $S_z$ consists of the terms in
$$ I = \\sum_i P^{S_z}_i = P^{S_z}_+ + P^{S_z}_-$$

$P^{S_z}_+$ is an event asserting that the electron has the property of being spin-up in the $z$ direction at a certain point in time.

A *history* is a sequence of events. As an example, the history for starting with a "ready" apparatus at time $t_0$ and then measuring spin-up in $z$ at time $t_1$ is
$$h = (I^S \\otimes P^\\epsilon_\\varnothing, \\; \\; P^{S_z}_+ \\otimes P^\\epsilon_+)$$


The standard probability postulate
$$\\mathcal{P}(n) = \\braket{\\psi | a_n}$$
can be written in terms of the projection operator for $\\ket{a_n}$ by expanding the complex square,
$$\\mathcal{P}(n) = \\bra{\\psi} P^A_n \\ket{\\psi}$$

To extend the probability postulate to make predictions about histories, we replace the measurement result projector $P^A_n$ with the class operator $C_h^\\dagger$
$$\\mathcal{P}(h) = \\bra{\\psi} C_h^\\dagger \\ket{\\psi}.$$

The *class operator* for a history is the product of its [Heisenberg picture](https://en.wikipedia.org/wiki/Heisenberg_picture) events. The class operator for $h$ is
$$ C^\\dagger_h  = \\Bigl( P^{S_z}_+ \\otimes P^\\epsilon_+  \\Bigr)(t_1) \\Bigl( I^S \\otimes P^\\epsilon_\\varnothing \\Bigr)(t_0)$$
$$ C^\\dagger_h  = U^\\dagger(t_1, t_0) \\Bigl( P^{S_z}_+ \\otimes P^\\epsilon_+  \\Bigr) U(t_1, t_0) \\Bigl( I^S \\otimes P^\\epsilon_\\varnothing \\Bigr)$$

Through its constituent Heisenberg projectors, the class operator brings together the events of a history with the unitary dynamics experienced by the system to characterize the occurrence of a particular history.

## Consistency conditions

In the standard aproach, the Born Rule makes predictions about postulated "measurement results" only. Now that we have extended the Born Rule to make predictions about histories, we must define the context in which these predictions are made.

*Consistency conditions* (or the *decoherence functional*) constrains the sets of histories for which the extened Born Rule may be applied. This constraint only requires that the Born Rule's predictions for a set of histories are consistent with probability theory (giving "consistent histories" its name). Consitency conditions provide objective criterion for when probabilities may be assigned, rather than subjective language about interaction with a “classical measuring apparatus".

A set of histories $S$ is a consitent when its elements are exhaustive
$$ \\sum_{h \\in S} C_h = I  $$
and mutually exclusive
$$ C^\\dagger_{h^\\prime} C_h = \\delta_{h, h^\\prime}C_h$$

To exemplify a consistent set of histories, consider Experiment 2:
<iframe src="spins.html?experiment=two" width="100%" height=400"></iframe>

+++ Click to see a set of histories representing all possible outcomes
$$\\begin{array}{llll}
S = & \\{ \\\\
 		&& h_1 = ( & I^S \\otimes P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_\\varnothing \\; \\; \\; (t_0) \\\\  \\\\ &&& P^{S_z}_+ \\otimes P^{\\epsilon_1}_+ \\otimes P^{\\epsilon_2}_\\varnothing, \\; \\; \\; (t_1) \\\\ \\\\  &&& P^{S_x}_+ \\otimes P^{\\epsilon_1}_+ \\otimes P^{\\epsilon_2}_+ , \\; \\; (t_2) \\\\
		&&), \\\\ \\\\
		&& h_2 = ( & I^S \\otimes P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_\\varnothing \\; \\; \\; (t_0) \\\\  \\\\ &&& P^{S_z}_+ \\otimes P^{\\epsilon_1}_+ \\otimes P^{\\epsilon_2}_\\varnothing, \\; \\; \\; (t_1) \\\\ \\\\  &&& P^{S_x}_- \\otimes P^{\\epsilon_1}_+ \\otimes P^{\\epsilon_2}_- , \\; \\; (t_2) \\\\ \\\\
		&&), \\\\ \\\\
		&& h_3 = ( & I^S \\otimes P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_\\varnothing \\; \\; \\; (t_0) \\\\  \\\\ &&& P^{S_z}_- \\otimes P^{\\epsilon_1}_- \\otimes P^{\\epsilon_2}_\\varnothing \\; \\; \\; (t_1) \\\\
		&&) \\\\ \\\\
		& \\}

\\end{array}$$
+++

Note that all histories begin with an initial event in which any value is allowed for spin and both analyzers have yet to record a measurement. Also note that the analyzers' measurements persist in future events.

Using the von Neumann measurement dynamics above, we construct unitary operators for this experimental setup.

$$ U(t_1, t_0) = P^{S_z}_+ \\otimes S^{\\epsilon_1}_{\\varnothing, +} \\otimes I^{\\epsilon_2} \\; \\; + \\; \\; P^{S_z}_- \\otimes S^{\\epsilon_1}_{\\varnothing, -}\\otimes I^{\\epsilon_2} $$

For $U(t_2, t_1)$, the dynamics are dependent on which event at $t_1$ occurs, since only spin-up electrons proceed to the second analyzer. So, we apply $\\epsilon_2$ swaperators to the $P^{\\epsilon_1}_+$ branch and the identity to all others.

$$ U(t_2, t_1) = P^{S_x}_+ \\otimes P^{\\epsilon_1}_+ \\otimes S^{\\epsilon_2}_{\\varnothing, +} \\; \\; + \\; \\; P^{S_x}_- \\otimes P^{\\epsilon_1}_+ \\otimes S^{\\epsilon_2}_{\\varnothing, -} \\; \\; + \\; \\; I^S \\otimes P^{\\epsilon_1}_- + P^{\\epsilon_1}_\\varnothing  \\otimes I^{\\epsilon_2} $$

Now we use these unitary operators to construct class operators for each history.

+++ Click to see the class operators for $S$
$$\\begin{array}{llll}

C^\\dagger_{h_1} &=  \\Bigl( P^{S_x}_+ \\otimes P^{\\epsilon_1}_+ \\otimes P^{\\epsilon_2}_+ \\Bigr) U(t_2, t_1) \\Bigl( P^{S_z}_+ \\otimes P^{\\epsilon_1}_+ \\otimes  P^{\\epsilon_2}_\\varnothing \\Bigr) U(t_1, t_0) \\Bigl( I^S \\otimes P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2, t_0) \\\\ \\\\
								 &=  \\Bigl(P^{S_x}_+ P^{S_z}_+ \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\bra{+_{\\epsilon_2}} \\Bigr) U^\\dagger(t_2,t_0) \\\\ \\\\
C^\\dagger_{h_2} &=  \\Bigl( P^{S_x}_- \\otimes P^{\\epsilon_1}_+ \\otimes P^{\\epsilon_2}_- \\Bigr) U(t_2, t_1) \\Bigl( P^{S_z}_+ \\otimes P^{\\epsilon_1}_+ \\otimes  P^{\\epsilon_2}_\\varnothing \\Bigr) U(t_1, t_0) \\Bigl( I^S \\otimes P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2, t_0) \\\\ \\\\
								 								 &=  \\Bigl(P^{S_x}_- P^{S_z}_+ \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\bra{-_{\\epsilon_2}} \\Bigr) U^\\dagger(t_2,t_0) \\\\ \\\\
C^\\dagger_{h_3} &=  \\Bigl( P^{S_z}_- \\otimes P^{\\epsilon_1}_- \\otimes  P^{\\epsilon_2}_\\varnothing \\Bigr) U(t_1, t_0) \\Bigl( I^S \\otimes P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2, t_0) \\\\ \\\\
								&=  \\Bigl(P^{S_z}_- \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{-_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2,t_0)
\\end{array}$$

+++

To show that $S$ is a consistent set of histories, we demonstrate orthogonality and exhaustivity.

+++ Click to see demonstration of orthogonality
$$\\begin{array}{llll}

C^\\dagger_{h_2} C_{h_1} &= \\Bigl(P^{S_x}_- P^{S_z}_+ \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\bra{-_{\\epsilon_2}} \\Bigr) U^\\dagger(t_2,t_0) U(t_2,t_0) \\Bigl(P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\\\ \\\\
												 &= \\Bigl(P^{S_x}_- P^{S_z}_+ \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\bra{-_{\\epsilon_2}} \\Bigr) \\Bigl(P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\\\ \\\\
												 &= \\Bigl(P^{S_x}_- P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\braket{-_{\\epsilon_2}|+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\\\ \\\\
												 &= \\Bigl(P^{S_x}_- P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; 0 \\Bigr) \\\\ \\\\
												 &= 0 \\\\ \\\\
C^\\dagger_{h_3} C_{h_1} &= \\Bigl(P^{S_z}_- \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{-_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2,t_0) U(t_2,t_0) \\Bigl(P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\\\ \\\\
												 &= \\Bigl(P^{S_z}_- P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\braket{-_{\\epsilon_1}|+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\braket{\\varnothing_{\\epsilon_2}|+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\\\ \\\\
												 &= 0 \\\\ \\\\
C^\\dagger_{h_3} C_{h_1} &= \\Bigl(P^{S_z}_- \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{-_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2,t_0) U(t_2,t_0) \\Bigl(P^{S_z}_+ P^{S_x}_- \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{-_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\\\ \\\\
												 &= \\Bigl(P^{S_z}_- P^{S_z}_+ P^{S_x}_- \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\braket{-_{\\epsilon_1}|+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\braket{\\varnothing_{\\epsilon_2}|-_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\\\ \\\\
												 &= 0 \\\\ \\\\
\\end{array}$$
+++

Notice that the pointer system is responsible for the orthogonality of these histories. For a family of histories to be consistent, some system (in this case, the apparatus) must keep persistent records of the electron's spin at the time of measurement. [Environmental decoherence](https://arxiv.org/abs/1911.06282) generalizes this process (hence the suggestive naming of the pointer system $\\mathcal{H}_\\epsilon$).

+++ Click to see demonstration of exhaustivity
Coming soon!
+++

## Mixed states

So far, we have used the first analyzer as a "state preparation" device to circumvent ignorance about the initial spin state. However, for a complete description of the experiment, the spin state of electrons leaving the oven must be identified.

The hot and chaotic environment of the oven randomizes the initial spin of the electron. This uncertainty is classical in nature. The electron leaves the oven with a definite spin state - we just don't know what it is.

Such a statistical ensemble of states is best represented by a mixed-state density operator

$$
\\begin{array}{llll}
	\\rho_0 &= \\frac{1}{2}\\Bigl(P^{S_z}_+ \\; + \\; P^{S_z}_- \\Bigr) \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\\\ \\\\
				  &= \\frac{1}{2}I^S \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing
\\end{array}
$$

which ensures that regardless of the first analyzer's orientation, it is equally likely to measure spin-up as spin-down.

### Density operator Born Rule

We can rearrange the standard Born Rule to write it in terms of the density operator
$$\\mathcal{P}(n) = \\bra{\\psi} P^A_n \\ket{\\psi}$$
$$\\mathcal{P}(n) = \\braket{\\psi|a_n} \\braket{a_n|\\psi}$$
$$\\mathcal{P}(n) = \\braket{a_n|\\psi}\\braket{\\psi|a_n}$$
$$\\mathcal{P}(n) = \\bra{a_n} \\rho_0 \\ket{a_n}$$

To again write the rule in terms of the projection operator for $a_n$,
$$ \\mathcal{P}(n) = Tr(P^A_n \\rho_0 P^A_n) $$

We claim that the analogous statement in the consistent histories approach is
$$ P(h) = Tr(C_h \\rho_0 C^\\dagger_h) $$

Now we can assign probabilities to each history for Experiment 2.

+++ $P(h_1) = Tr\\Bigl( C_{h_1} \\rho_0 C^\\dagger_{h_1} \\Bigr) = \\frac{1}{4}$
$$\\begin{array}{llll}
P(h_1) &= Tr\\Bigl( C_{h_1} \\rho_0 C^\\dagger_{h_1} \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( U(t_2, t_0) \\Bigl(P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\Bigl( \\frac{1}{2}I^S \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) C^\\dagger_{h_1} \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( U(t_2, t_0) \\Bigl( \\frac{1}{2} P^{S_z}_+ P^{S_x}_+ \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}}\\Bigr) C^\\dagger_{h_1}\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_+ \\otimes P^{\\epsilon_1}_\\varnothing \\otimes \\ket{+_{\\epsilon_2}} \\bra{\\varnothing_{\\epsilon_2}} \\Bigr) C^\\dagger_{h_1}\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_+ \\otimes P^{\\epsilon_1}_\\varnothing \\otimes \\ket{+_{\\epsilon_2}} \\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\Bigl(P^{S_x}_+ P^{S_z}_+ \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\bra{+_{\\epsilon_2}} \\Bigr) U^\\dagger(t_2,t_0) \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_+ P^{S_z}_+ \\otimes  \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\otimes P^{\\epsilon_2}_+ \\Bigr) U^\\dagger(t_2, t_0)\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_+ P^{S_z}_+ \\otimes  P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_+ \\Bigr) U^\\dagger(t_2, t_1)\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_+ P^{S_z}_+ \\otimes  P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_+ \\Bigr) \\Bigr) \\\\ \\\\
			 &= \\frac{1}{4}
\\end{array}$$
+++

+++ $P(h_2) = Tr\\Bigl( C_{h_2} \\rho_0 C^\\dagger_{h_2} \\Bigr) = \\frac{1}{4}$
$$\\begin{array}{llll}
P(h_2) &= Tr\\Bigl( C_{h_2} \\rho_0 C^\\dagger_{h_2} \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( U(t_2, t_0) \\Bigl(P^{S_z}_+ P^{S_x}_- \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{-_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\Bigl( \\frac{1}{2}I^S \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) C^\\dagger_{h_2} \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( U(t_2, t_0) \\Bigl( \\frac{1}{2} P^{S_z}_+ P^{S_x}_- \\; \\otimes \\; \\ket{+_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; \\ket{+_{\\epsilon_2}}\\bra{\\varnothing_{\\epsilon_2}}\\Bigr) C^\\dagger_{h_2}\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_- \\otimes P^{\\epsilon_1}_\\varnothing \\otimes \\ket{-_{\\epsilon_2}} \\bra{\\varnothing_{\\epsilon_2}} \\Bigr) C^\\dagger_{h_2}\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_- \\otimes P^{\\epsilon_1}_\\varnothing \\otimes \\ket{-_{\\epsilon_2}} \\bra{\\varnothing_{\\epsilon_2}} \\Bigr) \\Bigl(P^{S_x}_- P^{S_z}_+ \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_2}}\\bra{-_{\\epsilon_2}} \\Bigr) U^\\dagger(t_2,t_0) \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_- P^{S_z}_+ \\otimes  \\ket{\\varnothing_{\\epsilon_1}}\\bra{+_{\\epsilon_1}} \\otimes P^{\\epsilon_2}_- \\Bigr) U^\\dagger(t_2, t_0)\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_- P^{S_z}_+ \\otimes  P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_- \\Bigr) U^\\dagger(t_2, t_1)\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( \\Bigl( \\frac{1}{2}P^{S_z}_+ P^{S_x}_- P^{S_z}_+ \\otimes  P^{\\epsilon_1}_\\varnothing \\otimes P^{\\epsilon_2}_- \\Bigr) \\Bigr) \\\\ \\\\
			 &= \\frac{1}{4}
\\end{array}$$
+++

+++ $P(h_3) = Tr\\Bigl( C_{h_3} \\rho_0 C^\\dagger_{h_3} \\Bigr) = \\frac{1}{2}$
$$\\begin{array}{llll}
P(h_3) &= Tr\\Bigl( C_{h_3} \\rho_0 C^\\dagger_{h_3} \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( U(t_2, t_0) \\Bigl(P^{S_z}_- \\; \\otimes \\; \\ket{-_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) \\Bigl( \\frac{1}{2}I^S \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) C^\\dagger_{h_3} \\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( U(t_2, t_0) \\Bigl( \\frac{1}{2} P^{S_z}_- \\; \\otimes \\; \\ket{-_{\\epsilon_1}}\\bra{\\varnothing_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) C^\\dagger_{h_3}\\Bigr) \\\\ \\\\
			 &= Tr\\Bigl( U(t_2, t_1) \\Bigl( \\frac{1}{2} P^{S_z}_- \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) C^\\dagger_{h_3}\\Bigr) \\\\ \\\\
			 &= Tr \\Bigl( \\Bigl( \\frac{1}{2} P^{S_z}_- \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) C^\\dagger_{h_3}\\Bigr) \\\\ \\\\
			 &= Tr \\Bigl( \\Bigl( \\frac{1}{2} P^{S_z}_- \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) \\Bigl(P^{S_z}_- \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}}\\bra{-_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2,t_0)\\Bigr) \\\\ \\\\
			 &= Tr \\Bigl( \\Bigl( \\frac{1}{2} P^{S_z}_- \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}} \\bra{-_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2,t_0)\\Bigr) \\\\ \\\\
			 &= Tr \\Bigl( \\Bigl( \\frac{1}{2} P^{S_z}_- \\; \\otimes \\; \\ket{\\varnothing_{\\epsilon_1}} \\bra{-_{\\epsilon_1}} \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) U^\\dagger(t_2,t_1)\\Bigr) \\\\ \\\\
			 &= Tr \\Bigl( \\Bigl( \\frac{1}{2} P^{S_z}_- \\; \\otimes \\; P^{\\epsilon_1}_\\varnothing \\; \\otimes \\; P^{\\epsilon_2}_\\varnothing \\Bigr) \\Bigr) \\\\ \\\\
			 &= \\frac{1}{2}
\\end{array}$$
+++

## Future Work
- Reproduce Bayes theorem
- Trace out ignored measurements
- Trace out environment, where environment is the tensor product of all analyzers
`)

export default {
  data() {
    return {
      content
    }
  }
}
</script>
