<template>
  <div>
    <div class='markdown-body' v-html="content"></div>
  </div>
</template>

<script>

const mdContent = `
# Spins


<iframe src="spins.html?experiment=three" width="100%" height="500"></iframe>

Experiment 1. An exemplary experiment demonstrating incompatibility of physical variables, specifically the z-component of spin $S_z$ and the x-component of spin $S_x$.

[Full screen spins simulation](https://wilsonia.github.io/spins/)

[Source code](https://github.com/wilsonia/spins)

## Introduction

> If you’ve chosen the right data structures and organized things well, the algorithms will almost always be self-evident. Data structures, not algorithms, are central to programming.
>
> -- Rob Pike, "Notes on Programming in C", 1989

[In physics, a theory consists of a *mathematical formalism* and an *ontological interpretation*](https://arxiv.org/abs/1710.07212v6). The formalism is a set of mathematical statements that give the theory its predictive power. The interpretation assigns meaning to those predictions.

In scientific computing, the formalism of a theory constrains its data structures (and therefore its algorithms). Competing theories often have conflicting formalisms. A programmer then faces different constraints on their data structures. Thinking about the quote from Rob Pike on the importance of data structure choice in programming, we suggest it is worthwhile investigating how quantum measurement is modeled computationally when using the [relative states formalism](https://arxiv.org/pdf/1710.07212.pdf#section*.7).

We analyze how consecutive electron spin measurement is simulated using the [consistent histories approach to quantum mechanics](consistent_histories). The word *approach* is used to identify a particular choice of formalism and interpretation. We take the "standard" (or "Copenhagen") approach as the one [developed by Wener Heisenberg and Neils Bohr](https://en.wikipedia.org/wiki/Copenhagen_interpretation#cite_note-1), and contrast it with the "consistent histories" (or "decoherent histories") approach developed by [Robert B. Griffiths](https://link.springer.com/article/10.1007/BF01015734), [Murray Gell-Mann and James B. Hartle](http://web.physics.ucsb.edu/~quniverse/papers/qcqm2ndedppt.pdf), and others.

## Background

### Spins simulation
The inspiration for this research is [a pedagogical tool](https://physics.weber.edu/schroeder/software/Spins.html) used for [spins-first]() quantum mechanics courses in undergraduate physics programs. To acquaint students with [*complementary* (or *incompatible*) physical variables](https://en.wikipedia.org/wiki/Complementarity_(physics)), the program simulates consecutive ideal measurements of an electron's spin along varying directions. The program is a dynamic version of the example experiments in [David McIntyre's qunatum mechanics textbook](), in which exemplary experimental setups are displayed as tree graphs with [Stern-Gerlach analyzers](https://en.wikipedia.org/wiki/Stern%E2%80%93Gerlach_experiment) as nodes. 

Allowing students to configure and manipulate these experiments is useful for building intuition on one of the most unintuitive features of quantum mechanics.

#### Development revisions
Thre have been several iterations of the spins simulation, including:

- Thomas A. Moore's text-based program (1982)
- [Daniel V. Schroeder's GUI simulation for Mac Classic (1988), updated by Michael Martin (1993)](https://arxiv.org/pdf/1502.07036.pdf)
- [A Java port by David McIntyre and Lichun Jia (2000)](http://sites.science.oregonstate.edu/~mcintyre/ph425/spins/)
- [An HTML5/JavaScript version by Daniel V. Schroeder (2016)](https://physics.weber.edu/schroeder/software/Spins.html)

The JavaScript version has the advantage of running in any modern browser without installing any software.

We chose to create [an ES6 JavaScript version](https://wilsonia.github.io/spins/) of this simulation to [accomplish the following goals](https://github.com/wilsonia/spins):
- Correcting a minor bug in Schroeder's simulation (a counting error)
- Allow importing and exporting of experimental configurations
- Improve code readability by implementing [linear algebra](https://mathjs.org/), [tree traversal](https://deepdash.io/), and [tree visualization](https://d3js.org/) libraries
- Implement fixed positioning of nodes using the "tidy tree" algorithm to:
	- Help the student differentiate the analyzer's position in space from its magnet orientation
 	- Emphasize the branching structure of consecutive measurements
`
  
import 'katex/dist/katex.min.css'
import '@/assets/atom-one-light.css'
import 'github-markdown-css/github-markdown.css'

var md = require('markdown-it')({html: true}),
    mk = require('markdown-it-katex'),
    markdown_it_headings = require('markdown-it-github-headings'),
    mh = require('markdown-it-highlightjs')

md.use(mk)
md.use(mh)
md.use(markdown_it_headings, {prefixHeadingIds: false})

const content = md.render(mdContent)

export default {
  data() {
    return {
      content
    }
  }
}
</script>
