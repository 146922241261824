<template>
  <div>
    <div class='markdown-body' v-html="content"></div>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css'
import '@/assets/atom-one-light.css'
import 'github-markdown-css/github-markdown.css'

var md = require('markdown-it')({html: true}),
    mk = require('markdown-it-katex'),
    mh = require('markdown-it-highlightjs')

md.use(mk)
md.use(mh)

const content = md.render(`

# Ian Wilson
Welcome to my webpage! Here I host a portfolio of my work on projects completed throughout my education.

You can reach me via:
- [E-mail](i@nwilson.dev)
- [LinkedIn](https://www.linkedin.com/in/wilsonia/)
- [GitHub](https://github.com/wilsonia)

## Dynamic documents
Academic writing can be intimidating and difficult to extract information from, especially for readers new to the content discussed.

I am interested in strategies for efficient scientific communication that reduce the entrance barrier for newcomers and also conserve the time and energy of experienced researchers. In this site, I use collapsible blocks to hide proofs, embed interactive applications, and cite using in-line links that point to relevant sections.

My hope is that these small formatting changes from static journal articles significantly improves readability.

### Tech stack
[Vue](https://vuejs.org/) handles UI and desktop/mobile device scaling.

[markdown-it](https://github.com/markdown-it/markdown-it) lays out text content, converting markdown notes to HTML.

[markdown-it-katex](https://github.com/waylonflinn/markdown-it-katex) uses KaTeX for equation typesetting.

[markdown-it-collapsible](https://www.npmjs.com/package/markdown-it-collapsible) hides long proofs and tangents under collapsible elements.
`)

export default {
  data() {
    return {
      content
    }
  }
}
</script>
